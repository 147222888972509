<template>
    <div>
        <div v-for="form in forms" :key="form.name">
            <b-card
                :bg-variant="isActiveBG(form)"
            >
                <b-card-title :class="textWhite(form)">
                    {{ form.name }}
                </b-card-title>
                <div class="btn-section">
                    <b-button :variant="isActiveCSS(form)" @click="toggleActiveForm(form)" :disabled="form.isActive">{{ isActiveText(form) }}</b-button>
                    <b-button @click="editForm(form._id)">Edit</b-button>
                </div>
            </b-card>
        </div>
        <div v-if="forms.length==0" class="no-questionnaire">
            No Questionnaires yet
        </div>
    </div>
</template>

<script>

import AxiosService from '@/Service/AxiosService'
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
} from 'bootstrap-vue'
import { retrieveColumnLayout } from 'echarts/lib/layout/barGrid'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BCard,
        BCardText,
        BButton,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BRow,
        BCol,
        BLink,
        BImg,
    },
    data(){
        return {
            forms:[]
        }
    },

    created(){
        this.init();
    },
    methods:{
        init(){
            AxiosService.get("/getQuestionnaires").then(({data})=>{
                if(data) this.forms = data;
            })
        },
        editForm(id){
            this.$router.push(`/questionnaire-edit?id=${id}`)
        },
        isActiveText(form){
            if(form.isActive) return "Active"
            else return "Set Active"
        },
        isActiveCSS(form){
            if(form.isActive) return "secondary"
            else return "success"
        },
        isActiveBG(form){
            if(form.isActive) return "primary"
            else return ""
        },
        textWhite(form){
            if(form.isActive) return "text-white"
            else ""
        },
        toggleActiveForm(form){
            if(form.isActive) return;
            
            this.$swal({
                title: 'Attention',
                text: `Are you sure you want to set form "${form.name}" active`,
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: `Set Active`,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger ml-1',
                },
                showClass: {
                    popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
            }).then(result => {
                // console.log("result", result)
                if(!result.isConfirmed) return;
                
                AxiosService.post("/questionnaireActive", {id:form._id}).then(({error, data})=>{
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Questionnaire set active',
                        variant: 'success',
                        },
                    })
                    this.init()
                })
            })
        }
    }
}
</script>

<style scoped>
    .btn-section{
        display: flex;
        justify-content: space-between;
    }
    .no-questionnaire{
        background: var(--light);
        padding: 20px 10px;
        color: var(--gray-dark);
        border-radius: 10px;
    }
</style>